import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const OurSolutions = () => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          {/* <span className="sub-title">
                        <img src={starIcon} alt="icon" /> 
                        Our Solutions
                    </span> */}
          <h2 className="homepage-tittle">Zašto Tafoma?</h2>
          <p className="purple-text">
            Platforma dizajnirana posebno za mala i srednja preduzeća.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  src={"../../assets/images/homepage/cut-cost.png"}
                  alt="Money stays in the wallet."
                  className="single-solutions-box-img"
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              </div>
              <h3>Smanjeni troškovi</h3>
              <p>
                Zašto plaćati 3 ili 4 usluge, kada možete koristiti uslugu koja
                pruža sve funkcije na jednom mestu za jednu cenu.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  src={"../../assets/images/homepage/no-integration.png"}
                  alt="Person chosing from many options."
                  className="single-solutions-box-img"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </div>
              <h3>Bez integracija</h3>
              <p>
                Nema potrebe da trošite vreme i trud na integraciju alata koji
                su vam potrebni, Tafoma ih sve obezbeđuje.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  src={"../../assets/images/homepage/improves-productivity.png"}
                  alt="Person next to speedometer."
                  className="single-solutions-box-img"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                />
              </div>
              <h3>Poboljšava produktivnost</h3>
              <p>
                Tafoma je za vas najlakši način da poboljšate produktivnost
                svojih timova, bez obzira da li radite na daljinu ili ne.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutions;
